<template>
  <NuxtLink :to="to" :external="external">
    {{ textParts.head }}
    <span class="whitespace-nowrap"
      >{{ textParts.tail
      }}<SvgExtLink
        v-if="external"
        class="inline align-baseline w-3"
        :class="{
          'w-3': iconSize === 3,
          'w-4': iconSize === 4,
          'align-baseline': iconAlign === 'baseline',
          'align-text-top': iconAlign === 'text-top',
        }" /></span
  ></NuxtLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";

const props = defineProps<{
  to: RouteLocationRaw;
  external?: boolean;
  iconAlign?: "text-top" | "baseline";
  iconSize?: number;
}>();
const slots = useSlots();

const textParts = computed(() => {
  if (
    slots &&
    slots.default &&
    slots.default() &&
    slots.default()[0] &&
    slots.default()[0].children
  ) {
    const words = slots.default()[0].children?.toString().trim().split(" ");
    return {
      head: words?.slice(0, words.length - 1).join(" "),
      tail: words?.slice(words.length - 1)[0],
    };
  } else {
    return { head: "", tail: "" };
  }
});
</script>
